import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { selectDurations } from './selectors'
import {
  ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts'
import DashboardDurationsTooltip from './DashboardDurationsTooltip'
import minuteFormatter from 'lib/minuteFormatter'

const BAR_SIZE = 80

const messages = defineMessages({
  title: {
    id: 'oee.locationDashboardDurations.title',
    defaultMessage: 'TOP 10 [4 weeks]'
  },
  changeover: {
    id: 'oee.locationDashboardDurations.changeover',
    defaultMessage: 'Changeover'
  }
})

const DashboardDurations = ({ data, intl }) => {
  const fmt = minuteFormatter(intl)
  const categoryFormatter = cat => cat === 'changeover' ? intl.formatMessage(messages.changeover) : cat

  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ width: '100%', height: 700, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            width={450}
            height={700}
            data={data}
            margin={{
              top: 20, right: 20, bottom: 300, left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='label'
              angle={75}
              textAnchor='start'
              dx={5}
              dy={5}
              interval={0}
              tickFormatter={categoryFormatter}
            />
            <YAxis
              tickFormatter={fmt}
            />
            <Tooltip
              content={DashboardDurationsTooltip}
              formatter={fmt}
              labelFormatter={categoryFormatter}
            />
            <Bar
              dataKey='value'
              fill='#FFB1A1'
              barSize={BAR_SIZE}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

DashboardDurations.propTypes = {
  data: PropTypes.array.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectDurations(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(DashboardDurations)
