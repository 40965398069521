import { defineMessages } from 'react-intl'

const messages = defineMessages({
  minutesLabel: {
    id: 'lib.minuteFormatter.minutes',
    defaultMessage: '{minutes} min'
  }
})

const minuteFormatter = intl => minutes => intl.formatMessage(messages.minutesLabel, { minutes })

export default minuteFormatter
