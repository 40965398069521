import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'
import { compose } from 'redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { selectTrend } from './selectors'
import {
  ResponsiveContainer, ComposedChart, Line, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts'
import DashboardTrendTooltip from './DashboardTrendTooltip'

const BAR_SIZE = 30

const messages = defineMessages({
  availability: {
    id: 'oee.locationDashboardTrend.availability',
    defaultMessage: 'Availability'
  },
  calendarWeek: {
    id: 'oee.locationDashboardTrend.calendarWeek',
    defaultMessage: 'CW{weeks}'
  },
  oee: {
    id: 'oee.locationDashboardTrend.oee',
    defaultMessage: 'OEE'
  },
  performance: {
    id: 'oee.locationDashboardTrend.performance',
    defaultMessage: 'Performance'
  },
  quality: {
    id: 'oee.locationDashboardTrend.quality',
    defaultMessage: 'Quality'
  },
  target: {
    id: 'oee.locationDashboardTrend.target',
    defaultMessage: 'Target'
  },
  title: {
    id: 'oee.locationDashboardTrend.title',
    defaultMessage: '8-week OEE trend'
  },
  trend: {
    id: 'oee.locationDashboardTrend.trend',
    defaultMessage: 'Trend'
  }
})

const DashboardTrend = ({ data, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ width: '100%', height: 450, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            width={450}
            height={450}
            data={data}
            margin={{
              top: 20, right: 20, bottom: 20, left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='date'
              tickFormatter={date => intl.formatMessage(messages.calendarWeek, { weeks: moment(date).isoWeek() })}
            />
            <YAxis unit='%' ticks={[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100]} domain={[0, 100]} />
            <Tooltip
              isAnimationActive={false}
              content={DashboardTrendTooltip}
            />
            <Legend />
            <Bar
              dataKey='availability'
              name={intl.formatMessage(messages.availability)}
              barSize={BAR_SIZE}
              fill='#B4E8BD'
            />
            <Bar
              dataKey='performance'
              name={intl.formatMessage(messages.performance)}
              barSize={BAR_SIZE}
              fill='#FFE0BF'
            />
            <Bar
              dataKey='quality'
              name={intl.formatMessage(messages.quality)}
              barSize={BAR_SIZE}
              fill='#AEC9DF'
            />
            <Line
              type='monotone'
              dataKey='oee'
              name={intl.formatMessage(messages.oee)}
              stroke='#00BB5E'
              strokeWidth={3}
              dot={{ r: 5, stroke: '#00BB5E', fill: '#ffffff', strokeWidth: 2 }}
              activeDot={{ r: 5, stroke: '#00BB5E', fill: '#ffffff', strokeWidth: 3 }}
            />
            <Line
              type='monotone'
              dataKey='trend'
              name={intl.formatMessage(messages.trend)}
              stroke='#C91B24'
              strokeWidth={3}
              dot={false}
              activeDot={false}
            />
            <Line
              type='step'
              dataKey='target'
              name={intl.formatMessage(messages.target)}
              stroke='#363636'
              strokeWidth={3}
              dot={false}
              activeDot={false}
              strokeDasharray='8'
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

DashboardTrend.propTypes = {
  data: PropTypes.array.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectTrend(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(DashboardTrend)
