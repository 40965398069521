import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import moment from 'moment-timezone'
import heartbeat from 'lib/saga/heartbeat'
import api from 'lib/api/client'
import { SET_NAVIGATION, configureNavigation, setNavigationDate } from 'containers/Navigation/actions'
import { selectNavigationRange } from 'containers/Navigation/selectors'
import { TYPE_RANGE } from 'containers/Navigation/const'
import { selectToken, selectLocation } from 'oee/locationDashboard/selectors'
import {
  finishInit,
  startInit,
  receiveProjects,
  requestTimeline,
  receiveTimeline,
  receiveLocation,
  requestTrend,
  receiveTrend,
  requestDurations,
  receiveDurations,
  requestScrap,
  receiveScrap
} from './actions'
import {
  selectTimezone
} from './selectors'

const TREND_WEEKS = 8
const DURATIONS_WEEKS = 4
const DURATIONS_LIMIT = 10

export function * init () {
  yield put(startInit())

  const timezone = yield select(selectTimezone)
  yield put(configureNavigation(TYPE_RANGE))
  yield put(setNavigationDate(moment.tz({ hour: 0 }, timezone)))

  const token = yield select(selectToken)
  const id = window.location.pathname.split('/').slice(-1)[0]
  const [location, projects] = yield all([
    call(api.get, `/locations/${id}`, { token }),
    call(api.get, '/projects', { token })
  ])
  yield all([
    put(receiveLocation(location)),
    put(receiveProjects(projects))
  ])

  yield call(timeline)
  yield call(trend)
  yield call(durations)
  yield call(scrap)

  yield put(finishInit())
}

let decoratorIterator = 0

const timelineDataDecorator = ({ startedAt, finishedAt, ...rest }, timezone) => ({
  ...rest,
  startedAt: moment.tz(startedAt, timezone),
  finishedAt: moment.tz(finishedAt, timezone),
  key: (decoratorIterator += 1)
})

export function * timeline () {
  yield put(requestTimeline())
  const token = yield select(selectToken)
  const params = yield select(selectNavigationRange)
  const { id } = yield select(selectLocation)
  const data = yield call(api.get, `/oee/dashboard/location/${id}/timeline`, { params, token })
  const timezone = yield select(selectTimezone)

  yield put(receiveTimeline({
    summary: timelineDataDecorator(data.summary, timezone),
    timeline: data.timeline.map(row => timelineDataDecorator(row, timezone))
  }))
}

export function * trend () {
  yield put(requestTrend())
  const token = yield select(selectToken)
  const range = yield select(selectNavigationRange)
  const params = {
    weeks: TREND_WEEKS,
    to: range.to
  }
  const { id } = yield select(selectLocation)
  const data = yield call(api.get, `/oee/dashboard/location/${id}/trend`, { params, token })

  yield put(receiveTrend(data.trend))
}

export function * durations () {
  yield put(requestDurations())
  const token = yield select(selectToken)
  const range = yield select(selectNavigationRange)
  const params = {
    weeks: DURATIONS_WEEKS,
    limit: DURATIONS_LIMIT,
    to: range.to
  }
  const { id } = yield select(selectLocation)
  const data = yield call(api.get, `/oee/dashboard/location/${id}/durations`, { params, token })

  yield put(receiveDurations(data.durations))
}

export function * scrap () {
  yield put(requestScrap())
  const token = yield select(selectToken)
  const range = yield select(selectNavigationRange)
  const params = {
    weeks: DURATIONS_WEEKS,
    limit: DURATIONS_LIMIT,
    to: range.to
  }
  const { id } = yield select(selectLocation)
  const data = yield call(api.get, `/oee/dashboard/location/${id}/scrap`, { params, token })

  yield put(receiveScrap(data.scrap))
}

export default function * root () {
  if (process.env.NODE_ENV === 'development') {
    yield fork(heartbeat, 'OEE Location Dashboard')
  }

  yield call(init)

  yield takeLatest(SET_NAVIGATION, timeline)
  yield takeLatest(SET_NAVIGATION, trend)
  yield takeLatest(SET_NAVIGATION, durations)
  yield takeLatest(SET_NAVIGATION, scrap)
}
