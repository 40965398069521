import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'redux'
import { injectIntl, defineMessages, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import { selectScrap } from './selectors'
import {
  ResponsiveContainer, ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip
} from 'recharts'
import DashboardScrapTooltip from './DashboardScrapTooltip'

const BAR_SIZE = 80

const messages = defineMessages({
  title: {
    id: 'oee.locationDashboardScrap.title',
    defaultMessage: 'TOP 10 [4 weeks]'
  }
})

const DashboardScrap = ({ data, intl }) => {
  return (
    <div>
      <h2>{intl.formatMessage(messages.title)}</h2>
      <div style={{ width: '100%', height: 700, fontFamily: 'monospace' }}>
        <ResponsiveContainer>
          <ComposedChart
            width={450}
            height={700}
            data={data}
            margin={{
              top: 20, right: 20, bottom: 300, left: 20
            }}
            barGap={0}
          >
            <CartesianGrid stroke='#f5f5f5' />
            <XAxis
              dataKey='label'
              angle={75}
              textAnchor='start'
              dx={5}
              dy={5}
              interval={0}
            />
            <YAxis
            />
            <Tooltip
              content={DashboardScrapTooltip}
            />
            <Bar
              dataKey='value'
              fill='#2980B9'
              barSize={BAR_SIZE}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}

DashboardScrap.propTypes = {
  data: PropTypes.array.isRequired,
  intl: intlShape
}

const mapStateToProps = state => ({
  data: selectScrap(state)
})

export default compose(
  connect(mapStateToProps),
  injectIntl
)(DashboardScrap)
