import React from 'react'
import PropTypes from 'prop-types'

const DashboardScrapTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length > 0) {
    const { value } = payload[0]

    return (
      <div className='recharts-custom-tooltip'>
        <div className='recharts-custom-tooltip__label'>{label}</div>
        <div className='recharts-custom-tooltip__value'>{value}</div>
      </div>
    )
  }

  return null
}

DashboardScrapTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number
  })),
  label: PropTypes.string.isRequired
}

export default DashboardScrapTooltip
