import React from 'react'
import PropTypes from 'prop-types'

const mapPayloadToValues = payload => {
  let values = {}

  payload.forEach(row => {
    values[row.dataKey] = row
  })

  return values
}

const DashboardTrendTooltipValue = ({ color, name, value, target }) => (
  <div className='recharts-custom-tooltip__value'>
    <div className='recharts-custom-tooltip__dot' style={{ backgroundColor: color }} />
    {name}: {value} {target && `/ ${target}`}
  </div>
)

DashboardTrendTooltipValue.propTypes = {
  color: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  target: PropTypes.number
}

const DashboardTrendTooltip = ({ active, payload, label }) => {
  if (active && payload) {
    const { oee, availability, performance, quality, target } = mapPayloadToValues(payload)

    return (
      <div className='recharts-custom-tooltip recharts-custom-tooltip--dots'>
        <div className='recharts-custom-tooltip__label'>{label}</div>
        <DashboardTrendTooltipValue color={oee.stroke} name={oee.name} value={oee.value} target={target.value} />
        <DashboardTrendTooltipValue color={availability.fill} name={availability.name} value={availability.value} />
        <DashboardTrendTooltipValue color={performance.fill} name={performance.name} value={performance.value} />
        <DashboardTrendTooltipValue color={quality.fill} name={quality.name} value={quality.value} />
      </div>
    )
  }

  return null
}

DashboardTrendTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(PropTypes.shape({
    dataKey: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.number
  })),
  label: PropTypes.string.isRequired
}

export default DashboardTrendTooltip
