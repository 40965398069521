import React from 'react'
import PropTypes from 'prop-types'

const DashboardDurationsTooltip = ({ active, payload, label, formatter, labelFormatter }) => {
  if (active && payload && payload.length > 0) {
    const value = payload[0]

    return (
      <div className='recharts-custom-tooltip'>
        <div className='recharts-custom-tooltip__label'>{labelFormatter(label)}</div>
        <div className='recharts-custom-tooltip__value'>{formatter(value.value)}</div>
      </div>
    )
  }

  return null
}

DashboardDurationsTooltip.propTypes = {
  active: PropTypes.boolean,
  payload: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number
  })),
  label: PropTypes.string.isRequired,
  formatter: PropTypes.func.isRequired,
  labelFormatter: PropTypes.func.isRequired
}

export default DashboardDurationsTooltip
