import React from 'react'
import DashboardHeader from 'oee/locationDashboard/DashboardHeader'
import DashboardTimeline from 'oee/locationDashboard/DashboardTimeline'
import DashboardTrend from 'oee/locationDashboard/DashboardTrend'
import DashboardDurations from 'oee/locationDashboard/DashboardDurations'
import DashboardScrap from 'oee/locationDashboard/DashboardScrap'

export default () => (
  <React.Fragment>
    <DashboardHeader />
    <DashboardTimeline />
    <DashboardTrend />
    <DashboardDurations />
    <DashboardScrap />
  </React.Fragment>
)
