import { combineReducers } from 'redux'
import { handleActions } from 'redux-actions'
import Actions from 'oee/locationDashboard/actions'
import navigation from 'containers/Navigation/reducer'
import { allById, allIds } from 'lib/reducers'

const config = handleActions(
  {
    [Actions.SET_TOKEN]: (state, { payload: token }) => ({ ...state, token })
  },
  { token: null, locale: null, timezone: null }
)

const plant = handleActions(
  {
  },
  { name: '' }
)

const location = handleActions(
  {
    [Actions.RECEIVE_LOCATION]: (state, { payload: location }) => location
  },
  { name: '' }
)

const timeline = handleActions(
  {
    [Actions.RECEIVE_TIMELINE]: (state, { payload: data }) => {
      return {
        ...state,
        ...data
      }
    }
  },
  { timeline: [], summary: {} }
)

const trend = handleActions(
  {
    [Actions.RECEIVE_TREND]: (state, { payload: data }) => data
  },
  []
)

const durations = handleActions(
  {
    [Actions.RECEIVE_DURATIONS]: (state, { payload: data }) => data
  },
  []
)

const scrap = handleActions(
  {
    [Actions.RECEIVE_SCRAP]: (state, { payload: data }) => data
  },
  []
)

const init = handleActions(
  {
    [Actions.START_INIT]: state => ({ ...state, isFinished: false }),
    [Actions.FINISH_INIT]: state => ({ ...state, isFinished: true })
  },
  { isFinished: false }
)

const loading = handleActions(
  {
    [Actions.REQUEST_TIMELINE]: () => true,
    [Actions.RECEIVE_TIMELINE]: () => false
  },
  false
)

const projects = handleActions(
  {
    [Actions.RECEIVE_PROJECTS]: (state, { payload: projects }) => ({
      allById: allById(projects),
      allIds: allIds(projects)
    })
  },
  { allById: {}, allIds: [] }
)

export default combineReducers({
  config,
  plant,
  location,
  init,
  navigation,
  loading,
  timeline,
  trend,
  durations,
  projects,
  scrap
})
